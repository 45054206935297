<template>
  <v-app>
    <v-dialog v-model="isDialogVisible" width="500px">
      <!-- <template v-slot:activator="{ on }">
      <v-btn color="primary" dark depressed rounded v-on="on"
        ><v-icon>add</v-icon>Upload Lecture</v-btn
      >
    </template> -->
      <v-card class="mk-card-radius pa-4">
        <v-card-title class="title mk-highlight-text mb-5"
          >{{ !$language ? "Upload Lecture" : "লেকচার আপলোড করুন" }}
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="allValid"
            autocomplete="off"
            @submit.prevent="() => {}"
          >
            <v-text-field
              v-model.trim="formData.title"
              outlined
              :label="!$language ? 'Title' : 'শিরোনাম'"
              :rules="rules.title"
              :placeholder="
                !$language
                  ? 'Enter your lecture title here...'
                  : 'আপনার লেকচারের শিরোনাম লিখুন'
              "
            ></v-text-field>
            <v-file-input
              v-model="formData.file"
              outlined
              show-size
              chips
              :label="!$language ? 'File Upload' : 'ফাইল আপলোড করুন'"
              :placeholder="
                !$language
                  ? 'Upload the file here...'
                  : 'ফাইলটি এখানে আপলোড করুন'
              "
              :rules="rules.fileRules"
              @keypress.enter.native.prevent
            ></v-file-input>
            <v-checkbox
              v-model="formData.isDownloadable"
              :label="
                !$language
                  ? 'Allow students to download the content'
                  : 'শিক্ষার্থীদের কনটেন্ট ডাউনলোড করার অনুমতি দিন'
              "
            ></v-checkbox>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            outlined
            color="red accent-3"
            class="btn-outlined"
            @click="cancel"
          >
            <v-icon left>cancel</v-icon>
            {{ !$language ? "Cancel" : "বাতিল করুন" }}
          </v-btn>
          <v-btn
            depressed
            rounded
            color="primary"
            class="ml-2 btn-outlined"
            :loading="saving"
            @click="save"
          >
            <v-icon left>arrow_circle_up</v-icon>
            {{ !$language ? "Upload" : "আপলোড করুন" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { omitBy, isUndefined } from "lodash";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "LectureUploadDialog",
  props: {
    courseId: {
      type: String,
      default: undefined
    },
    should_popup_upload_dialog: Boolean
  },
  mixins: [FieldValidations],
  data() {
    return {
      formData: {
        title: undefined,
        object_name: undefined,
        file: undefined,
        isDownloadable: false
      },
      rules: {
        title: [
          v => this.required(v?.trim()),
          v => this.minLength(v?.trim(), 5),
          v => this.maxLength(v?.trim(), 300)
        ],
        fileRules: [
          v => this.required(v), // does not work without this syntax
          v => this.fileSizeLessThanOrEqual(v, 1024 * 1024 * 1024)
        ]
      },
      isDialogVisible: this.should_popup_upload_dialog,
      saving: false,
      allValid: true
    };
  },
  watch: {
    isDialogVisible: {
      handler(value) {
        if (!value) this.$emit("cancel");
      }
    }
  },
  methods: {
    cancel() {
      this.formData = {};
      this.isDialogVisible = false;
      this.$emit("cancel");
    },
    async save() {
      if (this.saving) return;
      if (!this.allValid) {
        await this.$refs.form.validate();
        this.warningState = true;
        return;
      }
      try {
        this.saving = true;
        const lectureData = omitBy(this.formData, isUndefined);
        await this.$store.dispatch("lecture/create", {
          courseId: this.courseId,
          lectureData
        });
        this.isDialogVisible = false;
        this.$emit("save", lectureData);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>
