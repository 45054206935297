<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        ><PageTitleSearchCreate
          :page-title="!$language ? 'Lectures' : 'লেকচার সমূহ'"
          :placeholder="
            !$language
              ? 'Search By Lecture Name'
              : 'লেকচার নাম দিয়ে সার্চ করুন'
          "
          :create-btn="!$language ? 'Create Lecture' : 'লেকচার তৈরি করুন'"
          @createEvent="should_popup_upload_dialog = true"
        >
        </PageTitleSearchCreate>
        <lecture-upload-dialog
          v-if="should_popup_upload_dialog"
          :course-id="courseId"
          :should_popup_upload_dialog="should_popup_upload_dialog"
          @cancel="should_popup_upload_dialog = false"
          @save="onUploadSuccess"
        ></lecture-upload-dialog
      ></v-col>
      <template v-if="fetchingContent">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>
      <no-content-component
        v-else-if="noContentCondition"
        :message="noContentMessage"
      ></no-content-component>
      <v-col
        v-else
        v-for="(lecture, i) in lectures"
        :key="i"
        xl="4"
        lg="6"
        md="12"
        sm="12"
        class="m-4"
      >
        <MLectureListItem
          :lecture="lecture"
          @share="shareLecture(i)"
        ></MLectureListItem>
      </v-col>

      <v-col v-if="shareLecturePanel" cols="12">
        <LectureSharePanel
          :lecture="lectureToShare"
          :share-panel="shareLecturePanel"
          :should_show_loading_icon="should_show_loading_icon"
          @closePanel="shareLecturePanel = false"
          @shareLecture="submitFormData"
        ></LectureSharePanel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LectureUploadDialog from "#ecf/lecture/components/LectureUploadDialog";
import MLectureListItem from "#ecf/lecture/components/MLectureListItem";
import LectureSharePanel from "#ecf/lecture/components/LectureSharePanel";
import { fromPairs } from "lodash";
import PageTitleSearchCreate from "@ecf/global/components/PageTitleSearchCreate";
import lectureService from "#ecf/lecture/services/LectureService";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "LectureListPage",
  components: {
    LectureUploadDialog,
    LectureSharePanel,
    MLectureListItem,
    PageTitleSearchCreate,
    NoContentComponent,
  },
  props: {
    courseId: { type: String, default: "" },
  },
  data() {
    return {
      fetchingContent: true,
      lectureToShare: undefined,
      shareLecturePanel: false,
      should_popup_upload_dialog: false,
      should_show_loading_icon: false,
    };
  },
  computed: {
    lectureRowCols() {
      return this.shareLecturePanel ? 8 : 10;
    },
    batches() {
      return this.$store.getters["batch/batches"][this.courseId] || [];
    },
    batchesMap() {
      return fromPairs(this.batches.map((b) => [b.batch_id, b]));
    },
    lecturesData() {
      return this.$store.getters["lecture/lectures"][this.courseId] || [];
    },
    lecture_shares() {
      return this.$store.state.lecture.lectureShareMap;
    },
    lectures() {
      return this.lecturesData.map((a) => {
        const shares = this.lecture_shares[a.object_id]
          ? this.lecture_shares[a.object_id]
          : {};
        const batches = this.batches.map((b) => {
          if (shares[b.batch_id]) {
            return Object.assign({}, shares[b.batch_id], {
              batch_title: b.batch_title,
              shared: true,
            });
          } else {
            return {
              batch_title: b.batch_title,
              batch_id: b.batch_id,
              shared: false,
            };
          }
        });
        this.$set(a, "batches", batches);
        const shared_batches = a.batches
          .filter((b) => b.shared)
          .map((b) => b.batch_title);
        this.$set(a, "shared_batches", shared_batches);
        return a;
      });
    },
    noContentCondition() {
      return this.lectures?.length == 0;
    },
    noContentMessage() {
      return !this.$language
        ? "You currently have no lectures"
        : "বর্তমানে আপনার কোনো লেকচার নেই";
    },
  },
  async created() {
    const isOnline = window.navigator.onLine;
    if ( !isOnline ){
      this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
      return;
    }
    this.fetchingContent = true;
    await this.fetchBatches();
    await this.fetchLectures();
    this.fetchingContent = false;
  },
  methods: {
    onUploadSuccess() {
      this.fetchBatches();
      this.fetchLectures();
      this.should_popup_upload_dialog = false;
    },
    shareLecture(idx) {
      this.lectureToShare = this.lectures[idx];
      this.shareLecturePanel = true;
    },
    async fetchLectures() {
      try {
        await this.$store.dispatch("lecture/list", { courseId: this.courseId });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchBatches() {
      try {
        await this.$store.dispatch("batch/list", {
          courseId: this.courseId,
          partner_code:
            this.$store.state.organization.currentOrganization.partner_code,
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async submitFormData(formData) {
      if (this.should_show_loading_icon) return;
      try {
        let finalFormData = [];
        this.should_show_loading_icon = true;
        if (Object.keys(formData).length > 0) {
          for (var formKey in formData) {
            this.$set(formData[formKey], "course_id", this.courseId);
            this.$set(formData[formKey], "object_type", "lecture");
            this.$set(
              formData[formKey],
              "object_id",
              this.lectureToShare.object_id
            );
            this.$set(
              formData[formKey],
              "partner_code",
              this.$route.params.partner_code
            );
            finalFormData.push(formData[formKey]);
          }
          await lectureService.shareToMultiple(finalFormData);
          await this.fetchLectures();
        } else {
          this.$set(formData, "course_id", this.courseId);
          this.$set(formData, "object_type", "lecture");
          this.$set(formData, "object_id", this.lectureToShare.object_id);
          this.$set(formData, "partner_code", this.$route.params.partner_code);
          await lectureService.deleteMultipleShare(formData);
          await this.fetchLectures();
        }
        this.shareLecturePanel = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.should_show_loading_icon = false;
      }
    },
  },
};
</script>
