var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500px" },
          model: {
            value: _vm.isDialogVisible,
            callback: function ($$v) {
              _vm.isDialogVisible = $$v
            },
            expression: "isDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "mk-card-radius pa-4" },
            [
              _c(
                "v-card-title",
                { staticClass: "title mk-highlight-text mb-5" },
                [
                  _vm._v(
                    _vm._s(
                      !_vm.$language ? "Upload Lecture" : "লেকচার আপলোড করুন"
                    ) + " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return (() => {}).apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.allValid,
                        callback: function ($$v) {
                          _vm.allValid = $$v
                        },
                        expression: "allValid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          label: !_vm.$language ? "Title" : "শিরোনাম",
                          rules: _vm.rules.title,
                          placeholder: !_vm.$language
                            ? "Enter your lecture title here..."
                            : "আপনার লেকচারের শিরোনাম লিখুন",
                        },
                        model: {
                          value: _vm.formData.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.title",
                        },
                      }),
                      _c("v-file-input", {
                        attrs: {
                          outlined: "",
                          "show-size": "",
                          chips: "",
                          label: !_vm.$language
                            ? "File Upload"
                            : "ফাইল আপলোড করুন",
                          placeholder: !_vm.$language
                            ? "Upload the file here..."
                            : "ফাইলটি এখানে আপলোড করুন",
                          rules: _vm.rules.fileRules,
                        },
                        nativeOn: {
                          keypress: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.formData.file,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "file", $$v)
                          },
                          expression: "formData.file",
                        },
                      }),
                      _c("v-checkbox", {
                        attrs: {
                          label: !_vm.$language
                            ? "Allow students to download the content"
                            : "শিক্ষার্থীদের কনটেন্ট ডাউনলোড করার অনুমতি দিন",
                        },
                        model: {
                          value: _vm.formData.isDownloadable,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "isDownloadable", $$v)
                          },
                          expression: "formData.isDownloadable",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-outlined",
                      attrs: {
                        rounded: "",
                        outlined: "",
                        color: "red accent-3",
                      },
                      on: { click: _vm.cancel },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("cancel")]),
                      _vm._v(
                        " " +
                          _vm._s(!_vm.$language ? "Cancel" : "বাতিল করুন") +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 btn-outlined",
                      attrs: {
                        depressed: "",
                        rounded: "",
                        color: "primary",
                        loading: _vm.saving,
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("arrow_circle_up"),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(!_vm.$language ? "Upload" : "আপলোড করুন") +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }