var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("PageTitleSearchCreate", {
                attrs: {
                  "page-title": !_vm.$language ? "Lectures" : "লেকচার সমূহ",
                  placeholder: !_vm.$language
                    ? "Search By Lecture Name"
                    : "লেকচার নাম দিয়ে সার্চ করুন",
                  "create-btn": !_vm.$language
                    ? "Create Lecture"
                    : "লেকচার তৈরি করুন",
                },
                on: {
                  createEvent: function ($event) {
                    _vm.should_popup_upload_dialog = true
                  },
                },
              }),
              _vm.should_popup_upload_dialog
                ? _c("lecture-upload-dialog", {
                    attrs: {
                      "course-id": _vm.courseId,
                      should_popup_upload_dialog:
                        _vm.should_popup_upload_dialog,
                    },
                    on: {
                      cancel: function ($event) {
                        _vm.should_popup_upload_dialog = false
                      },
                      save: _vm.onUploadSuccess,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.fetchingContent
            ? [
                _c("div", { attrs: { id: "loading-wrapper" } }, [
                  _c("div", { attrs: { id: "loading-text" } }, [
                    _vm._v("LOADING"),
                  ]),
                  _c("div", { attrs: { id: "loading-content" } }),
                ]),
              ]
            : _vm.noContentCondition
            ? _c("no-content-component", {
                attrs: { message: _vm.noContentMessage },
              })
            : _vm._l(_vm.lectures, function (lecture, i) {
                return _c(
                  "v-col",
                  {
                    key: i,
                    staticClass: "m-4",
                    attrs: { xl: "4", lg: "6", md: "12", sm: "12" },
                  },
                  [
                    _c("MLectureListItem", {
                      attrs: { lecture: lecture },
                      on: {
                        share: function ($event) {
                          return _vm.shareLecture(i)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
          _vm.shareLecturePanel
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("LectureSharePanel", {
                    attrs: {
                      lecture: _vm.lectureToShare,
                      "share-panel": _vm.shareLecturePanel,
                      should_show_loading_icon: _vm.should_show_loading_icon,
                    },
                    on: {
                      closePanel: function ($event) {
                        _vm.shareLecturePanel = false
                      },
                      shareLecture: _vm.submitFormData,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }